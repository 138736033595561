'use es6';

import { useLocalOverride } from './localVersionOverride';
import SprocketMenu from './SprocketMenu';
import PostFilterWidget from './PostFilterWidget';

(function () {
  /**
   * documentMode is an IE-only property
   * https://www.w3schools.com/jsref/prop_doc_documentmode.asp
   */
  // holds major version number for IE, or NaN if UA is not IE.
  // Support: IE 9-11 only
  var msie = window.document.documentMode;

  if (msie) {
    return;
  }

  var loadHubspotToolsMenu = function loadHubspotToolsMenu() {
    var menu = new SprocketMenu(window.hsVars);
    menu.showToolsMenuIfAuthor();
    var postFilterWidget = new PostFilterWidget();
    postFilterWidget.setup();
  };

  if (!useLocalOverride()) {
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', function init() {
        loadHubspotToolsMenu();
        document.removeEventListener('DOMContentLoaded', init);
      }, false);
    } else {
      loadHubspotToolsMenu();
    }
  }
})();