'use es6';

var LOCAL_STORAGE_KEY = 'HS_SPROCKET_MENU_LOCAL_OVERRIDE';
var LOCAL_SRC = 'https://local.hsappstatic.net/HubspotToolsMenu/static/js/index.js';

var localScriptExists = function localScriptExists() {
  return Array.from(document.body.getElementsByTagName('script')).some(function (scriptEl) {
    return scriptEl.src === LOCAL_SRC;
  });
};

export var useLocalOverride = function useLocalOverride() {
  var useLocal = window.localStorage.getItem(LOCAL_STORAGE_KEY) || false;

  if (useLocal) {
    // Assume that this IS the local version if the local script already exists
    return !localScriptExists();
  }

  return false;
};
export var loadLocalVersion = function loadLocalVersion() {
  var newScript = document.createElement('script');
  newScript.src = LOCAL_SRC;

  newScript.onload = function () {
    // Give the app a bit of time to load
    setTimeout(function () {
      var loadEvent = new Event('DOMContentLoaded');
      document.dispatchEvent(loadEvent);
    }, 100);
  };

  document.body.appendChild(newScript);
};

if (useLocalOverride()) {
  loadLocalVersion();
}